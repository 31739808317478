'use strict'
//不要问为什么有一个tools.js了还要分这么多工具文件不存在一个js工具文件，问就是懒
export default {
    clearRichText(texts){
        if(texts){
            let text1 = texts.replace(/<\/?(img|table)[^>]*>/g,"")
            let text2 = text1.replace(/<\/?.+?>/g,"")
            let text3 = text2.replace(/[ | ]*\n/g,"")
            let text4 = text3.replace(/ /g,"")
            return text4
        }
    },
}
