<template>
    <div class="container">
        <Y-header></Y-header>
        <div class="body-container">
            <div class="blocker"></div>
            <banner :data="bannerData" height="600px" :speed="bannerSpeed"></banner>
            <div style="margin-top: 80px">
                <center-title text="公司介绍" bold font-size="42" color="#333333" position="center"></center-title>
                <center-title text="成都原链科技有限公司" bold font-size="20" color="##808080" position="center"></center-title>
            </div>
            <div class="company-introduce">
                <img class="company-image" :src="cImage"/>
                <div class="">
                    <h2>原链科技</h2>
                    <h3>公司宗旨</h3>
                    <span>{{slogan}}</span>
                    <h2 style="margin-top: 60px">公司介绍</h2>
                    <span v-html="introduce">{{introduce}}</span>

                </div>
            </div>

            <div style="background-color: #F5F5F5;padding: 40px 0">
                <div>
                    <center-title text="取得成就及荣誉" bold font-size="42" color="#333333" position="center"></center-title>
                    <center-title text="共计获得200+专利与配套产品" bold font-size="20" color="##808080" position="center"></center-title>
                </div>
                <div class="numbers">
                    <div class="number-box" v-for="(item,i) in numbers" :key="i">
                        <div class="number-plus-box">
                            <p class="number">{{item.number}}<span class="plus">+</span></p>
                        </div>
                        <p class="text">{{item.text}}</p>
                    </div>
                </div>
                <div class="honors">
                    <vue-seamless-scroll ref="seamless" :data="honorData" :class-option="scrollOption" class="scroll">
                        <div class="scroll-box">
                            <img :src="item+'?quality=0.5'" v-for="(item,i) in honorData" :key="i"/>
                        </div>
                    </vue-seamless-scroll>
                </div>
            </div>
            <center-title text="公司地址及合作方式" bold font-size="42" color="#333333" position="center"></center-title>
            <center-title text="共计获得200+专利与配套产品" bold font-size="20" color="##808080" position="center"></center-title>
            <div style="margin: 0 160px">
                <div id="map" class="map-container"></div>
                <div class="icon-box" ref="contact">
                    <div class="item-box" v-for="(item,i) in icons">
                        <img class="item-icon" :src="item.src"/>
                        <span>{{item.title}}</span>
                        <span>{{item.content}}</span>
                    </div>
                </div>
            </div>
        </div>
        <Y-footer></Y-footer>
        <a-back-top/>
    </div>
</template>

<script>
import banner from "@/components/banner.vue";
import centerTitle from "@/components/centerTitle.vue";
import footer from "@/components/footer.vue";
import header from "@/components/header.vue";
import handleRequest from "@/utils/handleRequest";
import {bannerData, selectAboutData} from "@/api/apis";
import tools from "@/utils/tools";

export default {
    name: "about",
    components: {
        centerTitle,
        'Y-footer':footer,
        'Y-header':header,
        banner,
    },
    data() {
        return {
            numbers:[
                {
                    number:10,
                    text:'10项独立研发技术'
                },
                {
                    number:50,
                    text:'50+套成熟体系方案'
                },
                {
                    number:80,
                    text:'80+专利技术'
                },
            ],
            bannerData:[],
            bannerSpeed:2000,
            honorData:[],
            icons:[
                {
                    src:require('@/assets/电话销售.png'),
                    title:'咨询时间',
                    content:'周一至周五 9：00-18：00'
                },
                {
                    src:require('@/assets/邮箱附件.png'),
                    title:'联系方式',
                    content:'18682751018'
                },
                {
                    src:require('@/assets/暂无地址.png'),
                    title:'地址',
                    content:'成都市武侯区联邦财富中心A座1单元702'
                },

            ],
            slogan:'科技创新，精益求精，开拓进取，务实高效',
            cImage:'',
            introduce:'',
            map:null,
            infoWindow:null,
            center:[103.983867,30.633776],
        }
    },
    watch: {
        'toPhone':{
            immediate:true,
            handler(n,o){
                if(n){
                    this.$nextTick(()=>{
                        const elPos = this.$refs.contact.offsetTop
                        window.scrollTo({
                            behavior:"smooth",
                            top:elPos
                        })
                        setTimeout(()=>{
                            this.$store.commit('setToPhone',false)
                        },1000)
                    })

                }
            }
        }
    },
    mounted() {
        this.getBannerData();
        this.getAboutData();
    },
    computed:{
        toPhone(){
            return this.$store.state.toPhone
        },
        scrollOption(){
            return {
                step: 2.6, // 数值越大速度滚动越快
                limitMoveNum: 4, // 开始无缝滚动的数据量
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 3, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            }
        },
    },
    methods: {
        async getAboutData(){
            const data = handleRequest(await selectAboutData())
            data.img = JSON.parse(data.img)[0]
            data.license = JSON.parse(data.license)
            data.license = data.license.map(item=>item.url)
            this.cImage = data.img.url || ''
            this.introduce = tools.clearRichText(data.introduce)
            this.honorData = data.license
            // console.log(this.$refs.seamless.$el.children[0].style.display = 'flex')
            // this.$refs.seamless.$el.children[0].style.flexWrap = 'nowrap'
            // this.$refs.seamless.$el.children[0].style.width = '3000px'

            this.icons[1].content = data.phone
            this.icons[2].content = data.address
            this.searchPoint(data.address)
        },
        async getBannerData(){
            const data = handleRequest(await bannerData({type:'关于我们',state:1}))
            let banner = data.content
            banner.forEach(item=>{
                item.img = JSON.parse(item.img)[0]
            })
            this.bannerSpeed = (banner[0]?.second*1000) || this.bannerSpeed
            this.bannerData = banner
        },
        /**初始化地图 */
        initMap () {
            var buildings = new AMap.Buildings({
                wallColor: [255, 255, 255, 0.86],
                roofColor: 'rgba(255,255,255,0.38)',
                'zooms': [16, 18],
                'zIndex': 100,
                'heightFactor': 2//2倍于默认高度，3D下有效
            });//楼块图层
            this.map = new AMap.Map("map", {
                // layers: [imgLayer],
                layers: [
                    //只显示默认图层的时候，layers可以缺省
                    AMap.createDefaultLayer(), //高德默认标准图层
                    buildings,
                ],
                zoom: 17,
                center: this.center,
                pitch: 10, // 地图俯仰角度，有效范围 0 度- 83 度
                viewMode: "default", // 地图模式
                resizeEnable: true, //是否监控地图容器尺寸变化
                // mapStyle: "amap://styles/darkblue",
            });
            this.map.on("complete", (res) => {
                //do somethings
                this.addPoint(this.center)
            });
        },
        searchPoint(address){
            let pos = localStorage.getItem('companyPos')
            if(pos){
                console.log('当前有缓存')
                pos = pos.split(',')
                pos = pos.map(item=>item*1)
                this.center = pos
                this.initMap();
            }else{
                let placeSearch = new AMap.PlaceSearch()
                placeSearch.search(address,(status,res)=>{
                    if(res == 'USER_DAILY_QUERY_OVER_LIMIT'){
                        this.$message.error('地图搜寻接口次数已耗尽')
                    }else{
                        this.center = [res.poiList.pois[0].location.lng,res.poiList.pois[0].location.lat]
                        localStorage.setItem('companyPos',[this.center])
                    }
                    this.initMap();
                })
            }
        },
        initInfoWindow(marker){
            // 创建 infoWindow 实例
            this.infoWindow = new AMap.InfoWindow({
                isCustom: true,
                autoMove: true,
                closeWhenClickMap: false,
                showShadow: true,
                content: '<h2 style="width:242px;background-color: #FFFFFF;box-shadow: 0 0 3px 3px #c2c2c2;text-align: center">成都原链科技有限公司</h2>', //传入 dom 对象，或者 html 字符串
                offset: new AMap.Pixel(10, -40),
                retainWhenClose: true
            });
            this.infoWindow.open(this.map, marker.getPosition());
        },
        //添加点坐标
        addPoint (point) {
            this.map.clearMap();
            var marker = new AMap.Marker({
                map: this.map,
                position: point,
                content: '', //自定义覆盖物,
                // extData: point
            });

            this.initInfoWindow(marker);

            marker.on("click", e => {
                //do somethings
                console.log(e)
            });

        },

    },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1600px) {
    .company-introduce{
        margin: 0 91px !important;
    }
    .numbers{
        margin:0 350px !important;
    }
}
::v-deep .amap-logo {
    display: none;
    opacity: 0 !important;
}

::v-deep .amap-copyright {
    opacity: 0;
}
.container{
    .body-container{
        background-color: #FFFFFF;
        .blocker{
            height: 92px;
        }
        .company-introduce{
            margin: 0 260px;
            display: flex;
            padding: 40px 0;
            .company-image{
                border-radius: 12px ;
                width: 540px;
                margin-right:50px;
            }

        }
        .numbers{
            margin: 0 480px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .number-box{
                width: 220px;
                padding: 20px;
                text-align: center;

                .number-plus-box{
                    .number{
                        margin: 0;
                        font-size: 64px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 600;
                        background: linear-gradient(90deg, #00C8FF 0%, #3769FE 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        position: relative;
                    }
                    .plus{
                        font-size: 32px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 600;
                        background: linear-gradient(90deg, #00C8FF 0%, #3769FE 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        position: absolute;
                        right: 30px;
                    }
                }
                .text{
                    font-size: 18px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #1F1F1F;
                    line-height: 18px;
                }
            }
        }
        .honors{
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            .scroll{
                //width: 1300px;
                margin: 0 260px;
                height: 500px;
                overflow: hidden;
                .scroll-box{
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    img{
                        border-radius: 8px;
                        margin: 40px;
                        min-width: 240px;
                        //width: 240px;
                        height: 360px;
                        transition: all .3s ease-in-out;
                        &:hover{
                            box-shadow: 0 0 2px 3px #9a9a9a;
                            transform: scale(1.1);
                        }
                    }
                }
            }



        }
        .icon-box{
            padding: 40px 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .item-box{
                width: 220px;
                text-align: center;
                .item-icon{
                    width: 120px;
                }
                span{
                    font-size: 18px;
                    font-weight: 550;
                    display:block;
                }
            }

        }
        .map-container{
            width:100%;
            height: 400px;
            margin:20px 0;
            border-radius: 8px;
            box-shadow: 0 0 3px 3px #ececec;
            border: 1px solid #d7d7d7;
        }
    }
}
</style>
